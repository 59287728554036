.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.logo-container {
  transition: transform 0.06s ease-out;
}

.logo-container:hover {
  transform: scale(1.05);
  cursor: pointer;
}


.App-header {
  background-color: #282c34;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

footer
{
  color:rgb(175, 175, 175)
}

.vn_title {
  height: 2em;
  margin-bottom: 2em;
}

.App-link {
  color: #61dafb;
}

/* unvisited link */
a:link {
  color: #41a9ff;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: #41a9ff;
  text-decoration: none;
}

/* mouse over link */
a:hover {
  color: rgb(237, 244, 255);
}

/* selected link */
a:active {
  color: #acacac;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  margin-left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 0.2em;
  height: 0.2em;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.search-class {
  font-size: calc(10px + 2vmin);
  background-color: #282c34;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);
}

.search-container {
  position: absolute;
  background-color: #282c34;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.search-option {
  padding: 10px;
  cursor: pointer;
  text-align: left;
}
.search-option:hover, .search-option.active {
  background-color: #373f4fa9;
}


.recommendations-container {
  display: flex;
  flex-direction: column;
}

.recommendations-column {
  flex: 1;
}

.tooltip {
  position: fixed;
  z-index: 10;
  background-color: #515968;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.7);
  border: 1px solid #e3edff;
  padding: 10px;
  /* adjust position as necessary */
  width: 400px;
  font-size: calc(2vmin);
  overflow-y: auto;

  text-align: justify;
  align-items: left;
  
}

.tooltip h3 {
  padding: 0px;
  margin: 0px;
}

.tooltip-container {
  padding: 0px;
}

.image {
  width: 100%;
  height: 100%;
}

.nsfw-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nsfw-container.darken::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5); /* Background color with some opacity */
  filter: blur(16px);
  z-index: 1;
}

.nsfw-text {
  position: absolute;
  color: white;
  text-align: center;
  z-index: 2;
}

.blur {
  filter: blur(16px);
}

.tag {
  background-color: #282c34;
  border: 1px solid #e8ecf4;
  border-radius: 5px;
  padding: 4px;
  margin: 2px;
  font-size: calc(1.25vmin);
}

.tag-container {
  background-color: #3e4a60;
  border: 1px solid #e8ecf4;
  border-radius: 5px;
  padding: 4px;
  margin: 2px;
}


@media screen and (min-width: 900px) {
  .recommendations-container {
    flex-direction: row;
  }
}
